import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../Config';
import axios from 'axios';
import ReactPlayer from 'react-player';


export default function UploadAudio({ setIsLoading }) {


    const [audioList, setAudioList] = useState([]);


    useEffect(() => {
        fetchAudioList()
    }, []);


    const fetchAudioList = () => {
        const config = {
            method: "get",
            url: `${BASE_URL}/api/get-audio-list`,
            headers: {},
        };

        axios(config)
            .then((response) => {
                setIsLoading(false);
                // console.log(response["data"].data);
                setAudioList(response["data"].data)
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };


    const deleteHandler = (id, index) => {
        // alert(id)

        const config = {
            method: "get",
            url: `${BASE_URL}/api/delete-audio/${id}`,
            headers: {},
        };
        axios(config)
            .then((response) => {
                console.log(response)

                const updatedList = [...audioList.slice(0, index), ...audioList.slice(index + 1)];

                // Set the state with the updated list
                setAudioList(updatedList); // Assuming you are using useState


            })
            .catch((err) => {

                console.log(err);
            });

    }



    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);

            setIsLoading(true);

            axios
                .post(`${BASE_URL}/api/upload-audio`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    // Handle the response from the server (e.g., success message or update audioList).
                    console.log(response);
                    fetchAudioList()
                    setIsLoading(false);
                    setSelectedFile(null)
                })
                .catch((err) => {
                    // Handle errors.
                    console.error(err);
                    setIsLoading(false);
                });
        }
    };



    return (
        <>

            <div className='video-upload'>
                <h1>Audio Upload</h1>
                <div className='uploading-deal'>
                    <input
                        type="file"
                        accept="audio/mpeg"
                        onChange={handleFileChange}
                    />
                    <button onClick={handleUpload}>Upload Audio</button>
                </div>
            </div>
            <div className='playerbar'>


                {audioList?.map((ele, index) => (
                    <>
                        <div className='video-box' key={index}>
                            <p>{ele?._id}</p>

                            <ReactPlayer
                                className="videoplay"
                                url={ele.url}
                                controls={true}
                            />

                            <button type='button' onClick={(e) => deleteHandler(ele?._id, index)} >Delete</button>

                        </div>
                    </>

                ))}

            </div>
        </>

    )
}

