//Backend URL:

// export const BASE_URL = "https://7n7fbl8upe.execute-api.ap-south-1.amazonaws.com";

// export const BASE_URL = "http://localhost:3000";

export const BASE_URL = "https://car-ar-backend.dev.seraphic.io";



