import { React, useEffect, useState } from "react";
import { deepPurple } from "@mui/material/colors";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import DownloadIcon from "@mui/icons-material/Download";

import {
  Container,
  Grid,
  Avatar,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import ReactPlayer from "react-player";
import { useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { BASE_URL } from "../Config/index";

export default function FirmVideo() {
  const [videoList, setVideoList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isScanning, setIsScanning] = useState(false);
  const [stateName, setStateName] = useState("");
  const [cityList, setCityList] = useState([]);
  const [cityName, setCityName] = useState("");
  const getStateList = State.getStatesOfCountry("US");
  const [carwash_names, setCarwashName] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemPerPage] = useState(9);
  const indexOfLastVideo = page * itemPerPage;
  const indexOfFirstVideo = indexOfLastVideo - itemPerPage;


  const [firmName, setFirmName] = useState(null);

  const fetchVideo = (carwashId, page) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/getVideo?carwashId=${carwashId}&&page=${page}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        setIsLoading(false);
        console.log("res========", response);
        setFirmName(response?.data?.firmDetails?.name)
        const result = response?.data?.data.sort(function (a, b) {
          var c = new Date(a.created_at);
          var d = new Date(b.created_at);
          return d - c;
        });
        setVideoList(result);

        setPageData(response?.data?.pagination);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // Use effect

  useEffect(() => {
    console.log("searchParam", searchParams.get("query"));
    fetchVideo(searchParams.get("carwashId"), searchParams.get("page"));
  }, [searchParams]);

  // useEffect(() => {
  //     if (Object.keys(videoList).length > 0) {
  //         let currentVideo = videoList.slice(indexOfFirstVideo, indexOfLastVideo);
  //         console.log("currentVideo", currentVideo.length);
  //         setPageData(currentVideo);
  //     }
  // }, [videoList]);

  // const paginate = (page) => {
  //     setPage(page);
  //     const indexOfLastVideo = page * itemPerPage;
  //     const indexOfFirstVideo = indexOfLastVideo - itemPerPage;
  //     let currentImages = videoList.slice(indexOfFirstVideo, indexOfLastVideo);
  //     setPageData(currentImages);
  // };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchVideo(searchParams.get("carwashId"), newPage);
  };

  const openVideoPage = (video) => {
    navigate(`/video/${video._id}`, { state: video });
  };

  const handleSearch = (e, value) => {
    setSearch(value);
    if (cityName !== "" && stateName !== "" && value.name !== "") {
      setSearchParams({
        ["query"]: value.name,
        ["state"]: stateName,
        ["city"]: cityName,
      });
    } else {
      toast.error("All Fields Requireds");
    }
  };

  const handleQrError = (err) => {
    console.error(err);
  };

  const handleQrScan = (data) => {
    if (data) {
      setIsScanning(false);
      const query = data.split("?");
      let searchParams = new URLSearchParams(query[1]);

      console.log(
        searchParams.get("query"),
        searchParams.get("state"),
        searchParams.get("city")
      );
      setSearchParams({
        ["query"]: searchParams.get("query"),
        ["state"]: searchParams.get("state"),
        ["city"]: searchParams.get("city"),
      });
    }
  };

  const handleStateChange = (e, value) => {
    if (value) {
      setStateName(value.name);
      const getCityList = City.getCitiesOfState("US", value.isoCode);
      setCityList(getCityList);
    }
  };

  const handleCityChange = (e, value) => {
    if (value) {
      setCityName(value.name);
    }
    console.log("value", value);
  };

  // Scrolling useffect
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const div2 = document.getElementById("div2");
      const div3 = document.getElementById("div3");

      // Scroll div1 fully before allowing div2 and div3 to scroll
      div2.style.transform = `translateY(-${Math.min(
        scrollTop,
        window.innerHeight
      )}px)`;
      div3.style.transform = `translateY(-${Math.min(
        scrollTop,
        window.innerHeight
      )}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div className="carwash-video-wrapper">
      <div className="tophead">
        <Avatar
          className="avatarbar"
          sx={{ bgcolor: deepPurple[500], width: 40, height: 40 }}
        >
          {/* <Link to="/">CarAR</Link> */}
          CarAR
        </Avatar>
        <h5>{firmName}</h5>
      </div>

      <Container>
        {/* <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid key={1} item>
                                {/* <Button variant="outlined" startIcon={<HomeIcon />}>
                                    <Link to="/">Home</Link>
                                </Button> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
      </Container>
      <Grid container className="videocontainer scrolling-container">
        {!isLoading &&
          videoList.map((video, index) => (
            <div className="full-height-wrapper" key={index}>
              <Grid className="videorow" id={`grid-${index}`} padding={0} item>

                <SingleClip video={video} />

              </Grid>
            </div>
          ))}
        {pageData.length === 0 && isLoading && (
          <Box sx={{ textAlign: "center", display: "flex" }}>
            <CircularProgress />
            <Typography variant="h5">Loading...</Typography>
          </Box>
        )}
        <Grid container justifyContent="center" spacing={2} mt={"10px"}>
          <Grid key={1} item>
            {/* <Pagination
                                color="secondary"
                                count={
                                    videoList && videoList.length > 0
                                        ? parseInt(videoList.length / 9)
                                        : 10
                                }
                                variant="outlined"
                                size="medium"
                                onChange={(event, value) => paginate(value)}
                            /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid className="pagination-grid">
        <Stack spacing={2}>
          <Pagination
            className="paginate"
            count={pageData?.totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </Grid>
    </div>
  );
}



const SingleClip = ({ video }) => {

  // console.log('videovideovideo', video)

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };


  const handleSendSms = () => {
    const link = video?.signed_url;
    const smsLink = `sms:?&body=Checkout video: ${encodeURIComponent(link)}`;
    window.location.href = smsLink;
  };

  const handleDownload = (e) => {
    e.preventDefault();
    window.open(video?.signed_url, "_blank").focus();
  };

  return (
    <>

      <Box className="videocontainerbox">
        <Card
          className="videoboxes"
        // onClick={(e) => openVideoPage(video)}
        >
          <CardContent className="videocardcontent">
            <ReactPlayer
              url={video.signed_url}
              className="videoplayer"
              controls={true}
              width="100%"
            />
            <div className="overlayvideo topdetailbar" >
              <div className="leftbar">
                <h4>Car Wash: {video.carwash_name}</h4>

                <ul>
                  <li>Date:{" "}
                    {new Date(video.created_at).toLocaleDateString()}
                  </li>
                  <li>Time:{" "}
                    {new Date(video.created_at).toLocaleTimeString()}
                  </li>
                </ul>
              </div>
            </div>
            <div className="overlayvideo">

              <div className={`rytbar ${isOpen ? "open" : ""}`}>
                <button
                  type="button"
                  className="openshareitem"
                  onClick={handleToggle}
                >
                  <svg
                    className="sahreicon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                  >
                    <circle
                      cx="27"
                      cy="27"
                      r="27"
                      fill="black"
                      fill-opacity="0.4"
                    />
                    <circle
                      cx="27"
                      cy="27"
                      r="26.5"
                      stroke="white"
                      stroke-opacity="0.54"
                    />
                    <path
                      d="M36.707 25.2928L28.707 17.2928C28.5671 17.153 28.389 17.0578 28.195 17.0192C28.0011 16.9806 27.8 17.0004 27.6173 17.0761C27.4346 17.1518 27.2785 17.2799 27.1686 17.4443C27.0587 17.6087 27 17.802 27 17.9998V21.5448C24.2683 21.7976 21.7292 23.0606 19.8797 25.0868C18.0302 27.113 17.0033 29.7564 17 32.4998V33.9998C17.0002 34.2074 17.0649 34.4098 17.1853 34.5789C17.3056 34.748 17.4756 34.8755 17.6717 34.9436C17.8678 35.0118 18.0802 35.0172 18.2795 34.9592C18.4788 34.9011 18.6551 34.7825 18.784 34.6198C19.7637 33.4547 20.9658 32.4966 22.3199 31.8014C23.6741 31.1062 25.1533 30.6878 26.671 30.5708C26.721 30.5648 26.846 30.5548 27 30.5448V33.9998C27 34.1975 27.0587 34.3908 27.1686 34.5552C27.2785 34.7197 27.4346 34.8478 27.6173 34.9235C27.8 34.9991 28.0011 35.0189 28.195 34.9804C28.389 34.9418 28.5671 34.8466 28.707 34.7068L36.707 26.7068C36.8945 26.5193 36.9998 26.265 36.9998 25.9998C36.9998 25.7346 36.8945 25.4803 36.707 25.2928ZM29 31.5858V29.4998C29 29.2346 28.8946 28.9802 28.7071 28.7927C28.5196 28.6051 28.2652 28.4998 28 28.4998C27.745 28.4998 26.704 28.5498 26.438 28.5848C23.7429 28.8331 21.1774 29.8571 19.052 31.5328C19.2932 29.3272 20.3395 27.2881 21.9906 25.8059C23.6416 24.3238 25.7813 23.5026 28 23.4998C28.2652 23.4998 28.5196 23.3944 28.7071 23.2069C28.8946 23.0194 29 22.765 29 22.4998V20.4138L34.586 25.9998L29 31.5858Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    className="crossicon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                  >
                    <circle
                      cx="27"
                      cy="27"
                      r="27"
                      fill="black"
                      fill-opacity="0.4"
                    />
                    <circle
                      cx="27"
                      cy="27"
                      r="26.5"
                      stroke="white"
                      stroke-opacity="0.54"
                    />
                    <path
                      d="M33 33L21 21M33 21L21 33"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
                <div className="openmodal">
                  <FacebookShareButton url={video?.signed_url}>
                    <FacebookIcon round={true} />
                  </FacebookShareButton>

                  <WhatsappShareButton url={video?.signed_url}>
                    <WhatsappIcon round={true} />
                  </WhatsappShareButton>

                  <button className="btnsms" onClick={handleSendSms}>
                    <img src="/images/sms.png" alt="smsicon" />
                  </button>

                  {/* Additional share buttons or actions go here */}

                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownload}
                  >

                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}