import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import VideoPage from "./pages/VideoPage";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import UploadBackgroundVideo from "./pages/UploadBackgroundVideo";
import UploadAudio from "./pages/UploadAudio";
import UploadMedia from "./pages/UploadMedia";
import CarWashingList from "./pages/CarWashingList";
import FirmVideo from "./pages/FirmVideo";


function App() {
  return (
    <div className="wrapper">
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>

          <Route exact path="/" element={<Home />} />

          <Route path="/video/:id" element={<VideoPage />} />

          <Route exact path="/firm-video" element={<FirmVideo />} />

          <Route path="/upload-background-video" element={<UploadBackgroundVideo />} />

          <Route path="/upload-audio" element={<UploadAudio />} />

          <Route path="/upload-media" element={<UploadMedia />} />

          <Route path="/car-washing-list" element={<CarWashingList />} />

        </Routes>

      </Router>
    </div>
  );
}

export default App;
