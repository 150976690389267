import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../Config';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Avatar } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

export default function CarWashingList() {

    const [carWashList, setCarWashList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getCarWashList();
    }, [])


    const getCarWashList = () => {
        var config = {
            method: "get",
            url: `${BASE_URL}/api/carwash-list`,
            headers: {},
        };

        axios(config)
            .then(function (response) {
                console.log('response', response);

                const data = response?.data?.data;

                setCarWashList(data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const [selectedOption, setSelectedOption] = useState();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here
        if (!selectedOption) {
            alert('Select Car Wash');
        } else {
            navigate(`/firm-video/?carwashId=${selectedOption}&&page=1`)
        }
    };


    return (

        <>


            <div className='videobar'>


                <div className='container'>
                    <div className='logobar'>
                        <Avatar sx={{ bgcolor: deepPurple[500], width: 80, height: 80 }}>
                            {/* <Link to="/">CarAR</Link> */}
                            CarAR
                        </Avatar>
                    </div>



                    <div className="caroption">
                        <h2 className='text-center'>Car Wash list</h2>
                        <form className='caroptionform' onSubmit={handleSubmit}>
                            <label htmlFor="dropdown">Select an option:</label>
                            <div className='dblock'>
                                <select id="dropdown" value={selectedOption} onChange={handleOptionChange}>
                                    <option value="">Select option</option>
                                    {carWashList.map((ele, index) => (
                                        <option value={ele?._id}>{ele?.name}</option>
                                    ))}
                                </select>
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>





        </>

    );


}
