import { React, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { deepPurple } from "@mui/material/colors";
import {
  Box,
  Card,
  CardContent,
  Avatar,
  Container,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import HomeIcon from "@mui/icons-material/Home";
import { BASE_URL } from '../Config/index';



export default function VideoPage() {
  const { id } = useParams();
  const [state, setState] = useState({});

  useEffect(() => {
    fetchVideo(id);
  }, [id]);

  const fetchVideo = (id) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/video/${id}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        const result = response["data"].data;

        console.log('resultresultresultresultresult', result)
        setState(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownload = (e) => {
    e.preventDefault();
    window.open(state.short_url, "_blank").focus();
  };



  const handleShare = async (link) => {

    try {

      // const response = await fetch(link);
      // console.log('response', response)

      // const blob = await response.blob();
      // console.log('blob', blob)

      // const url = URL.createObjectURL(blob);
      // console.log('url', url)

      // const a = document.createElement('a');
      // console.log('a', a)

      // const file = new File([blob], 'video.mp4', { type: blob.type });
      // console.log('file', file)

      // a.href = url;
      // a.download = 'video' + Date.now() + '.mp4';
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      if (navigator.share) {
        console.log('11')
        await navigator.share({
          // files: [file],
          title: 'Car Wash',
          text: 'Check out this video!',
          url: link
        });
        console.log('22')

      } else {
        alert('Web Share API not supported in this browser. Please use a modern browser.');
      }

    } catch (error) {
      alert('Error fetching or sharing the video:', error);
    }

  };


  // const phoneNumber = "8146994995";
  const [phoneNumber, setPhoneNumber] = useState('');

  const message = 'adfdsfsd'


  const handleSendSms = () => {
    const link = state?.short_url;
    const smsLink = `sms:?&body=Checkout video: ${encodeURIComponent(link)}`;
    window.location.href = smsLink;
  };


  // Example usage:
  // downloadAndShare('your_video_link_here');



  const handleInstagramShare = () => {

    const caption = 'checkout video';

    const encodedCaption = encodeURIComponent(caption || '');
    const encodedLink = encodeURIComponent(state?.short_url || '');

    // Construct the Instagram share URL with pre-filled caption and link
    const instagramShareUrl = `https://www.instagram.com/?caption=${encodedCaption}&url=${encodedLink}`;

    // Open Instagram in a new tab
    window.open(instagramShareUrl, '_blank');

  };


  return (
    <div>


      <Avatar sx={{ bgcolor: deepPurple[500], width: 80, height: 80 }}>
        CarAR
      </Avatar>
      <Container>
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid key={1} item>
                <Button variant="outlined" startIcon={<HomeIcon />}>
                  <Link to="/">Home</Link>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* 
        <div className="btnshare-video">
          <button className="bntshare"
            onClick={() => handleShare(state.short_url)}
          // onClick={() => handleShare('https://sourcefy-dev.s3.us-east-1.amazonaws.com/big_buck_bunny_720p_1mb%20%282%29.mp4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjED0aCmFwLXNvdXRoLTEiRjBEAiASFYZTkDFfuHCWZtri9oLzOkAFn9%2BItdqWbvq5olLn%2FgIgOqNXsNv5LMYK0vIPHZzsx7jI0h5DdFdg%2BcyxqTrN9Csq7QIIhv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw1NDU1MjI1OTcwMjMiDMYfuplM1%2FfyA5mXJSrBAi82XhjsP9PCJN4GgZ6j30CRs5v12ow6eQY1FjTGGgDAVZ4uCCO3DLyWmm%2B%2FWQAeXWX9ntUyEcq941hJNJxdT5jnb1seNpGXN20NW5eWN46pYwdjWRe80vLewqncjvh8SMswGUBY8ng2mNv1cn9pHS1tgZaHHYHcbR5kfWqYzseNAHGGp%2By9Zj1yu8XOJo6WUd1VWCmQ1B6SD%2BAfx5PM6HN9lFtZCIQ7b8rtmGOB9FeMiGmJdH060eD%2BoF7ctAhh9OR1n6tOqJOpPPvv%2BGw5bkCTvwwI0BWs50PgllpLVR%2B42C61M3yAmhIktA9WlY3JzsGgiNoOQBSTFUUdB1patzefRrA0UBNJ%2FhUHn4xLrEbVvi7h74Qoy%2BNc1KCl%2Bnqz%2FB9OgheWMp9rdAQRppT%2FczGBMt%2Ffrd8Tf9gEQk02aVxnJzDYuNaqBjq0AlGBaOeGZztJaSBe7U9IZQokgA2vt9pkAMdO5PSPathY7vGM50e6RYQDJlSBezi93M%2FerbO%2BbQAJJQrkK6ZuJt7Nruxji6szO8OStOXngCZNHXyuCOCMx1nTDoL1FV%2BfbtLAAXexuHE%2BiRAvMJLmaZrxOeygjmxzlBD%2B13A0%2FJmJn6yzwb62%2Bnh9Aw6w2uI3veKh%2FuOwjprljB4RFYhBFFOh16dlk2ee59Uv1GM7Kq2Nb%2BVUTtHgbvgjcdpvGpNPb7uNrj9sPzdpzp112WFrGx3Nwe3pSjgQEULLJMFQvu0IzNdf2sJMQ8P8AeoygoeFDD4rHEXZeBUTD%2BbkTVHV8Rbk48LjKOuLtqvTpDSWj%2FRAhN9H24YlsBSXhpjs2upaxfYIoB3eyoqYjG9iextRvAsdLE8m&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231116T094926Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAX6A5OHSP3HBE3JJ6%2F20231116%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=23802da7043ba9ea5097a9f335484026a5ca2257ed5c9269d8a86a31d40ae97d')}
          // onClick={() => handleShare('https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4')}
          >Share
          </button>
        </div> */}

        <Box>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" className="contentcenter">
                Car Wash: {state.carwash_name}
              </Typography>
              <Typography variant="body1" className="contentcenter">
                Date: {new Date(state.created_at).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" className="contentcenter" mb={2}>
                Time: {new Date(state.created_at).toLocaleTimeString()}
              </Typography>
              <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid key={1} item>
                      <ReactPlayer
                        url={state.short_url}
                        width={"100%"}
                        controls={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ margin: "10px", textAlign: "center" }}>
                <FacebookShareButton url={state?.short_url}>
                  <FacebookIcon round={true} />
                </FacebookShareButton>



                <WhatsappShareButton url={state?.short_url}>
                  <WhatsappIcon round={true} />
                </WhatsappShareButton>


                <button className="btnsms" onClick={handleSendSms}>
                  <img src="/images/sms.png" alt="smsicon" />
                </button>

                {/* <TwitterShareButton url={state?.short_url}>
                  <TwitterIcon round={true} />
                </TwitterShareButton> */}

                {/* <button onClick={handleInstagramShare}>
                  Share on Instagram
                </button> */}

              </Box>
              <Box sx={{ margin: "10px", textAlign: "center" }}>
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={handleDownload}
                >
                  Download
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </div>
  );
}
