import React, { useState } from 'react'
import Loader from './Loader';
import { Avatar } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import UploadBackgroundVideo from './UploadBackgroundVideo';
import UploadAudio from './UploadAudio';

export default function UploadMedia() {

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const [isLoading, setIsLoading] = useState(false);


    return (
        <>
            <div className='videobar'>
                {isLoading ?
                    <Loader />
                    : null
                }

                <div className='container'>
                    <div className='logobar'>
                        <Avatar sx={{ bgcolor: deepPurple[500], width: 80, height: 80 }}>
                            {/* <Link to="/">CarAR</Link> */}
                            CarAR
                        </Avatar>
                    </div>

                    <div className="tabs">
                        <button onClick={() => handleTabClick(0)} className={activeTab === 0 ? 'active upload-tabs' : ''}>Upload Background Video</button>
                        <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active upload-tabs' : ''}>Upload Audio</button>
                    </div>

                </div>
            </div>

            <div className="tab-content">
                {activeTab === 0 && <UploadBackgroundVideo setIsLoading={setIsLoading} />}
                {activeTab === 1 && <UploadAudio setIsLoading={setIsLoading} />}
            </div>
        </>



    )
}
