import { React, useEffect, useState } from "react";
import { deepPurple } from "@mui/material/colors";
import {
  TextField,
  Container,
  Grid,
  Avatar,
  Button,
  Card,
  CardContent,
  Typography,
  Paper,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  CircularProgress,
  IconButton,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import ReactPlayer from "react-player";
import { useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QrReader from "react-qr-reader";
import { Country, State, City } from "country-state-city";
import { toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import Pagination from "@mui/material/Pagination";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Stack from "@mui/material/Stack";
import { BASE_URL } from '../Config/index';

export default function Home() {
  const [videoList, setVideoList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isScanning, setIsScanning] = useState(false);
  const [stateName, setStateName] = useState("");
  const [cityList, setCityList] = useState([]);
  const [cityName, setCityName] = useState("");
  const getStateList = State.getStatesOfCountry("US");
  const [carwash_names, setCarwashName] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [page, setPage] = useState(1);
  const [itemPerPage] = useState(9);
  const indexOfLastVideo = page * itemPerPage;
  const indexOfFirstVideo = indexOfLastVideo - itemPerPage;

  const fetchVideo = (query, state, city) => {
    const config = {
      method: "get",
      url: `${BASE_URL}/api/getVideo?q=${query}&state=${state}&city=${city}`,
      // url: `http://localhost:3000/api/getVideo?q=${query}&state=${state}&city=${city}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        setIsLoading(false);
        console.log(response["data"].data);
        const result = response["data"].data.sort(function (a, b) {
          var c = new Date(a.created_at);
          var d = new Date(b.created_at);
          return d - c;
        });
        setVideoList(result);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const fetchCarwashList = () => {
    var config = {
      method: "get",
      url: `${BASE_URL}m/api/carwash`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        const data = response.data.data;
        const item_name = [];
        data.forEach((element) => {
          item_name.push({ name: element });
        });
        setCarwashName(item_name);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Use effect

  useEffect(() => {
    console.log("searchParam", searchParams.get("query"));
    fetchVideo(
      searchParams.get("query"),
      searchParams.get("state"),
      searchParams.get("city")
    );
  }, [searchParams]);

  useEffect(() => {
    fetchCarwashList();
  }, []);

  useEffect(() => {
    if (Object.keys(videoList).length > 0) {
      let currentVideo = videoList.slice(indexOfFirstVideo, indexOfLastVideo);
      console.log("currentVideo", currentVideo.length);
      setPageData(currentVideo);
    }
  }, [videoList]);

  const paginate = (page) => {
    setPage(page);
    const indexOfLastVideo = page * itemPerPage;
    const indexOfFirstVideo = indexOfLastVideo - itemPerPage;
    let currentImages = videoList.slice(indexOfFirstVideo, indexOfLastVideo);
    setPageData(currentImages);
  };

  const openVideoPage = (video) => {
    navigate(`/video/${video._id}`, { state: video });
  };

  const handleSearch = (e, value) => {
    setSearch(value);
    if (cityName !== "" && stateName !== "" && value.name !== "") {
      setSearchParams({
        ["query"]: value.name,
        ["state"]: stateName,
        ["city"]: cityName,
      });
    } else {
      toast.error("All Fields Requireds");
    }
  };

  const handleQrError = (err) => {
    console.error(err);
  };

  const handleQrScan = (data) => {
    if (data) {
      setIsScanning(false);
      const query = data.split("?");
      let searchParams = new URLSearchParams(query[1]);

      console.log(
        searchParams.get("query"),
        searchParams.get("state"),
        searchParams.get("city")
      );
      setSearchParams({
        ["query"]: searchParams.get("query"),
        ["state"]: searchParams.get("state"),
        ["city"]: searchParams.get("city"),
      });
    }
  };

  const handleStateChange = (e, value) => {
    if (value) {
      setStateName(value.name);
      const getCityList = City.getCitiesOfState("US", value.isoCode);
      setCityList(getCityList);
    }
  };

  const handleCityChange = (e, value) => {
    if (value) {
      setCityName(value.name);
    }
    console.log("value", value);
  };

  return (
    <div>
      <Avatar sx={{ bgcolor: deepPurple[500], width: 80, height: 80 }}>
        {/* <Link to="/">CarAR</Link> */}
        CarAR
      </Avatar>
      {!searchParams.has("query") && !isScanning && (
        <Grid
          sx={{
            minWidth: "100%",
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          spacing={0}
          alignItems="center"
          justify="center"
        >
          <FormControl variant="outlined">
            <Autocomplete
              id="state_name"
              options={getStateList}
              getOptionLabel={(option) => option.name}
              style={{ width: 250, marginBottom: "10px" }}
              onChange={handleStateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  variant="outlined"
                  id="state_name_text"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Autocomplete
              id="city_name"
              options={cityList}
              getOptionLabel={(option) => option.name}
              style={{ width: 250, marginBottom: "10px" }}
              onChange={handleCityChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  variant="outlined"
                  id="city_name_text"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </FormControl>
          <FormControl variant="outlined">
            {stateName.length > 0 && cityName.length > 0 && (
              <Autocomplete
                id="carwash_name"
                options={carwash_names}
                getOptionLabel={(option) => option.name}
                style={{ width: 250, marginBottom: "10px" }}
                onChange={handleSearch}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Car Wash Name"
                    variant="outlined"
                    id="carwash_name_text"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            )}
          </FormControl>
          <Button
            variant="outlined"
            sx={{ marginTop: "15px" }}
            startIcon={<QrCodeIcon />}
            onClick={(e) => setIsScanning(true)}
          >
            Scan QR
          </Button>
        </Grid>
      )}

      {isScanning && (
        <Box sx={{ maxHeight: "100px" }}>
          <QrReader
            style={{
              maxHeight: "100px",
            }}
            delay={300}
            onError={handleQrError}
            onScan={handleQrScan}
            style={{ width: "100%" }}
          />
        </Box>
      )}

      {searchParams.has("query") && (
        <Container>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid key={1} item>
                  <Button variant="outlined" startIcon={<HomeIcon />}>
                    <Link to="/">Home</Link>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "5px", marginBottom: "10px" }}
          >
            {pageData.length > 0 &&
              !isLoading &&
              pageData.map((video, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                  <Box key={index}>
                    <Card
                      sx={{ margin: "5px" }}
                      onClick={(e) => openVideoPage(video)}
                    >
                      <CardContent>
                        <ReactPlayer
                          url={video.signed_url}
                          key={index}
                          controls={true}
                          width="100%"
                        />
                        <Typography variant="h5" component="div">
                          Car Wash: {video.carwash_name}
                        </Typography>
                        <Typography variant="body1">
                          Date:{" "}
                          {new Date(video.created_at).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body1">
                          Time:{" "}
                          {new Date(video.created_at).toLocaleTimeString()}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              ))}
            {pageData.length === 0 && isLoading && (
              <Box sx={{ textAlign: "center", display: "flex" }}>
                <CircularProgress />
                <Typography variant="h5">Loading...</Typography>
              </Box>
            )}
            <Grid container justifyContent="center" spacing={2} mt={'10px'}>
              <Grid key={1} item>
                <Pagination
                  color="secondary"
                  count={
                    videoList && videoList.length > 0
                      ? parseInt(videoList.length / 9)
                      : 10
                  }
                  variant="outlined"
                  size="medium"
                  onChange={(event, value) => paginate(value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}
